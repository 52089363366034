import React from 'react';

export const ErrorPage: React.FC = () => {
  return (
    <div className="error-page">
      <img src="/images/invalid-payment-link.svg"/>
      <h1>Payment link is not valid</h1>
      <div className="info-text">Make sure the link you're using is correct and hasn't been modified.</div>
    </div>
  );
};
