import { axiosApiClient } from './api-client';

export async function getPaymentInfo(receiptId: string) {
  const { data } = await axiosApiClient.get(`/payment/${receiptId}`);
  return data;
}

export async function handleCardPayment(receiptId: string, paymentData: any) {
  const { data } = await axiosApiClient.post(`/payment/handle-payment`, {
    ...paymentData,
    receiptId,
  });
  return data;
}

export async function handlePaypalPayment(receiptId: string, paymentData: any) {
  const { data } = await axiosApiClient.post(`/payment/handle-paypal-payment`, {
    ...paymentData,
    receiptId,
  });
  return data;
}
