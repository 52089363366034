import React from 'react';
import './TermsAgreement.css';

interface TermsAgreementProps {
  setTermsConfirmation: (value: boolean) => void;
}

export const TermsAgreement: React.FC<TermsAgreementProps> = ({ setTermsConfirmation }) => {

  return (
    <section>
      <h1>
        Terms Agreement
      </h1>
      <div className='section-container'>
        <div className='terms-and-conditions-text'>I, my spouse if married, my power of attorney and/or legal
          representative (client hereafter) has engaged R&amp;G Brenner Tax + Accounting (RGB hereafter) to
          prepare/assist in my tax return(s) (personal, corporate, fiduciary, trust, gift, partnership or exempt),
          foreign bank account FINCEN report, voluntary disclosure report, tax resolution (offers in compromise,
          Installment Agreements, etc.) and/or other tax related matter(s) for the Total Fee listed. As the client I/we
          understand and agree to the following: a) client will timely provide complete, correct &amp; organized
          information for the preparation of the tax return(s). This includes but is not limited to: W-2's, 1099's,
          1098's, profit and loss statements, general ledgers, log books, check book registers, letters from taxing
          authorities, etc. b) client will verify that all physical documentation submitted to RGB will be stamped as
          “RECEIVED” by RGB and returned to client &amp; client will NOT authorize or submit any tax forms for filing
          until any &amp; all questions/concerns have been answered to client’s satisfaction. c) Client
          understands &amp; accepts full responsibility for any errors/omissions on client’s part that might inhibit or
          delay the filing of client’s taxes in any way and/or cause incorrect reporting to any taxing agencies
          including but not limited to corrected wage/income statement received by RGB after incorrect wage/income
          statements were entered into RGB systems. Additional fees may be assessed when client omits information
          necessary to complete preparation and/or is not submitted within 15 days of a filing deadline. d) to keep
          adequate records to substantiate any and all income and deductions claimed. e) RGB will use due diligence in
          collecting information but will not audit client’s books or records to verify correctness. f) RGB will apply
          the tax law to the best of its ability, at the time of filing, to provide a true and correct tax return.
          Client will hold RGB harmless for any tax law changes made after the time of filing that affect their tax
          liability, penalties and/or interest. g) each tax return or service provided by RGB to client will have a
          separate and unique receipt identifier and fee. Audit protection services also known as “RGB Shield” may be
          included in your tax preparation fee if the indicator on Client’s final receipt for services is marked as
          “Inc.” “RGB Shield” is only available &amp; active for current year paid-in-full tax returns, and is not
          offered or available for any prior year tax filing(s). For more “RGB Shield” information visit rgbrenner.com.
          h) Anything client provides RGB to prepare client’s tax return is confidential (IRC§ 7525), but not protected
          by any accountant-client privilege. i) RGB will not disclose any information to outside concerns unless
          compelled by law or by client’s written request/authorization, including written and/or verbal disclosures. j)
          RGB restricts client’s information to the members of RGB’s firm who need access to this information in order
          to complete the work client hired RGB to do. k) RGB will provide client with a statement of our practice in
          regard to confidentiality with your tax return copies. l) Payment for service(s) will be due at the completion
          of the tax return. A retainer may be collected before any work begins. No tax return may leave or be
          transmitted from an RGB office until all previous and current RGB balances due are paid- in-full. Any
          potential refunds issued by RGB will be in the form of store credit &amp; excludes any assessed interest
          charges as client had access to monies a taxing authority is/has contesting/contested unless corresponding tax
          return is covered by “RGB Shield” as per clause (g) above. m) RGB will provide a complete tax return ready for
          either mail or E-file to the appropriate tax agencies and client was given the option to receive a paper copy
          of all completed tax return(s). Client agrees to be auto-enrolled for 18 months in RGB’s “RGB Cloud” program
          to access copies of client’s tax return(s) securely &amp; digitally via the World Wide Web or RGB’s mobile
          application.“RGB Cloud” &amp; RGB mobile application access can be suspended after 18 months as Client’s
          status will no longer be considered active. Client will notify RGB immediately in writing to the address
          listed in clause (x) below if client does not wish to participate in the “RGB Cloud” program and/or RGB mobile
          application. n) All physical documents supplied to complete client’s tax return have been returned to client
          and RGB will not be responsible for retaining client’s records except that which is required by the IRS
          circular 230 section code 10.20 and 10.28, &amp; client permits RGB to safely shred any supplied documents
          left unclaimed for 120 days after the filing of client’s tax return. o) RGB pricing is by the forms used to
          provide a complete return. Some forms are required by regulation even though they do not make up the delivered
          tax return (i.e. EITC check list, 8453, 8879, AMT, Depreciation schedules, etc). p) RGB will provide a good
          faith quote for services at the time of the interview or remote client quote request with the understanding
          the fee may change depending on the forms &amp; time necessary to complete client’s return. Additional fees
          may be assessed for bookkeeping, missed appointments, letter requests, additional copies of returns,
          representation work, mail, storage of information, etc. q) In the event any taxing authority postpones the
          initial electronic filing date for any of client’s tax forms, client authorizes RGB to hold all return(s)
          until a filing date has been approved by taxing authorities. r) Most taxing agencies—including the
          IRS &amp; NY State—require tax returns to be filed electronically (ELF). In the event client is assessed a
          penalty by any taxing agency for failing to file returns ELF, client holds RGB harmless, and client agrees it
          is client’s sole responsibility to sign and mail all non-ELF filed tax returns s) If client chooses to direct
          deposit/debit refunds or taxes due, client has provided &amp; verified that all bank routing &amp; account
          numbers were correctly submitted and entered into RGB’s tax software. RGB will not be held responsible for
          incorrect account information. t) RGB is not now or any time in the future responsible for client’s tax
          liabilities (personal, corporate or otherwise), or any potential IRS/State(s) refund delays or assessed
          levies/liens. u) To contact RGB first if any errors or omissions are discovered. RGB stands behind its work
          and will use all proper and regular remedies to correct unintentional errors/omissions made by RGB
          free-of-charge. v) To forfeit any &amp; all potential claims against RGB if client fails to allow RGB to first
          correct any potential mistakes as stated in clause (u). w) RGB requires a current &amp; valid email address to
          efficiently contact client with important tax &amp; RGB related information. Client agrees that email
          address(es) were correctly entered into RGB systems, and authorizes RGB to use email for tax &amp; RGB related
          communications. x) If a mobile phone number was supplied to RGB, client authorizes RGB to send tax related
          SMS/text messages. Client can request to opt-out of SMS/text communications by sending a written request to
          RGB’s executive office; 111 East Merrick Road, Valley Stream, NY 11580. y) RGB prohibits any RGB associate
          from setting up any online accounts or delivering/sending any “hard” or digital tax related documents or
          payments for/to any &amp; all taxing authorities on the client’s behalf absent a legal power of attorney, with
          the sole exception being the E-filing of the client’s tax return. Client agrees that only the client will set
          up accounts and deliver/send tax related documents or payments to any &amp; all authorities. z) If client
          elects to participate in any RGB promotion, client agrees to abide by all promotional rules and restrictions
          as stated for each promotion listed online at rgbrenner.com/promotions. aa) Client acknowledges they have
          received/reviewed all required Department of Consumer Affairs material including but not limited to the
          Consumer Bill of Rights. ab) Client agrees that any fees paid to a 3rd party collection company to collect
          unpaid balance dues will be added to the total Client’s fee due RGB. ac) TO AUTHORIZE RGB TO COMMENCE TAX WORK
          FOR THE AGREED TOTAL FEE LISTED ON CLIENT’S FINAL RECEIPT. IN THE EVENT CLIENT REQUESTS TO HAVE TAX RELATED
          DOCUMENTS MAILED, E-MAILED OR DELIVERED, CLIENT HOLDS RGB HARMLESS AND DOES SO AT CLIENT’S OWN RISK.
        </div>
        <div className='checkbox-container'>
          <div className='round-cb'>
            <input type='checkbox' id='checkbox' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTermsConfirmation(e.target.checked);
            }} />
            <label htmlFor='checkbox'></label>
          </div>
          <div className="checkbox-text">I agree to the terms, conditions and R&G Brenner client engagement agreement below.</div>
        </div>
      </div>
    </section>
  );
};
