import React from 'react';
import './InvoiceInfo.css';

interface InvoiceInfoProps {
  paymentAmount: number;
  setPaymentAmount: (amount: number) => void;
  first_name: string;
  last_name: string;
  street_addr1: string;
  state: string;
  zipcode: string;
  receipt_number: string;
  actual_fee: number;
  rewards: number;
  pmts: number;
  due: number;
}

export const InvoiceInfo: React.FC<InvoiceInfoProps> = ({
                                                          first_name,
                                                          last_name,
                                                          street_addr1,
                                                          state,
                                                          zipcode,
                                                          receipt_number,
                                                          actual_fee,
                                                          rewards,
                                                          pmts,
                                                          due,
                                                          paymentAmount,
                                                          setPaymentAmount,
                                                        }) => {
  function handlePaymentAmountChange(event: any) {
    let value = Math.min(event.target.value as number, due);
    if (value < 0) {
      value = 0;
    }
    setPaymentAmount(value);
  }

  return (
    <section>
      <h1>
        Secure Payment Form
      </h1>
      <div className='section-container'>
        <div className='title-row'>
          <h2>
            Your invoice
          </h2>
        </div>
        <div className='invoice-info-container'>
          <div className='info-section'>
            <div className='info-row'>
              <div>Client name:</div>
              <div>{first_name} {last_name}</div>
            </div>
            <div className='info-row'>
              <div>Address:</div>
              <div>{street_addr1}, {state} {zipcode}</div>
            </div>
          </div>
          <div className='info-section'>
            <div className='info-row'>
              <div>Receipt number:</div>
              <div>{receipt_number}</div>
            </div>
          </div>
          <div className='info-section'>
            <div className='info-row info-number-view'>
              <div>Total fee:</div>
              <div>${(actual_fee || 0).toFixed(2)}</div>
            </div>
            <div className='info-row info-number-view'>
              <div>Rewards applied:</div>
              <div>${(rewards || 0).toFixed(2)}</div>
            </div>
            <div className='info-row info-number-view'>
              <div>Prior payments:</div>
              <div>${(pmts || 0).toFixed(2)}</div>
            </div>
            <div className='divider' />
            <div className='info-row info-number-view'>
              <div>Balance due:</div>
              {due !== 0 ? <div>${(due || 0).toFixed(2)}</div> : <div>$0.00</div>}
            </div>
            {due !== 0 && <div className='info-row info-number-view'>
              <div>Payment amount:</div>
              <div className='usd-input-wrapper'>
                <input
                  type='number'
                  step='0.01'
                  value={paymentAmount}
                  max={due}
                  onChange={handlePaymentAmountChange}
                />
                <span>$</span>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>

  );
};
