import React, { useEffect, useState } from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { getPaymentInfo, handleCardPayment, handlePaypalPayment } from './api/payment-api';

import { PaymentForm } from './Payment/PaymentForm';
import { InvoiceInfo } from './InvoiceInfo/InvoiceInfo';
import { TermsAgreement } from './TermsAgreement/TermsAgreement';
import { ErrorPage } from './InfoPages/ErrorPage';
import { PaymentUnsuccessful } from './InfoPages/PaymentUnsuccessful';
import { Header } from './Header/Header';
import { PaymentSuccessful } from './InfoPages/PaymentSuccessful';

function App() {
  const [invoiceInfo, setInvoiceInfo] = useState<any>();
  const [paymentLoaded, setPaymentLoaded] = useState<boolean>(false);
  const [receiptId, setReceiptId] = useState<string>('');
  const [termsConfirmation, setTermsConfirmation] = useState<boolean>(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);
  const [paymentStatus, setPaymentStatus] = useState<'SUCCESS' | 'ERROR' | undefined>();
  const [paymentType, setPaymentType] = useState<'CARD' | 'PAY_PAL'>('CARD');

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const receiptIdParam = searchParams.get('r');
    if (!receiptIdParam) {
      setPaymentLoaded(true);
      return;
    }
    setReceiptId(receiptIdParam);
    getPaymentInfo(receiptIdParam)
      .then((data: any) => {
        setPaymentLoaded(true);
        setInvoiceInfo(data);
        setPaymentAmount(data.due);
      })
      .catch(e => {
        setPaymentLoaded(true);
      });
  }, []);

  const handleCardPaymentSubmit = async (data: any) => {
    try {
      const paymentResult = await handleCardPayment(receiptId, { ...data, amount: paymentAmount });
      if (paymentResult?.resultCode === 'Error') {
        setPaymentStatus('ERROR');
      } else {
        setPaymentStatus('SUCCESS');
      }
    } catch (e) {
      setPaymentStatus('ERROR');
      console.error(e);
    } finally {
      setSubmitEnabled(true);
    }
  };

  const handlePaypalPaymentSubmit = async (paypalOrderId?: any) => {
    try {
      const paymentResult = await handlePaypalPayment(receiptId, { paypalOrderId });
      if (paymentResult?.resultCode === 'Error') {
        setPaymentStatus('ERROR');
      } else {
        setPaymentStatus('SUCCESS');
      }
    } catch (e) {
      console.error(e);
      setPaymentStatus('ERROR');
    } finally {
      setSubmitEnabled(true);
    }
  };

  return (
    <div className='app-root'>
      <Header />
      {paymentStatus === 'ERROR' && <PaymentUnsuccessful />}
      {paymentStatus === 'SUCCESS' && <PaymentSuccessful />}
      {paymentLoaded && (!receiptId || !invoiceInfo) && <ErrorPage />}
      {!paymentStatus && invoiceInfo &&
        <div className='payment-container'>
          <InvoiceInfo receipt_number={receiptId} paymentAmount={paymentAmount}
                       setPaymentAmount={setPaymentAmount} {...invoiceInfo} />
          {invoiceInfo.due !== 0 && <TermsAgreement setTermsConfirmation={setTermsConfirmation} />}
          {invoiceInfo.due !== 0 && <PaymentForm termsAndConditionsConfirmed={termsConfirmation}
                                                 submitEnabled={submitEnabled}
                                                 paymentAmount={paymentAmount}
                                                 setSubmitEnabled={setSubmitEnabled}
                                                 paymentType={paymentType}
                                                 setPaymentType={setPaymentType}
                                                 onCardPaymentSubmit={handleCardPaymentSubmit}
                                                 onPaypalPaymentSubmit={handlePaypalPaymentSubmit} />}
        </div>
      }
    </div>
  );
}

export default App;
