import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../utils';
import Cards, { Focused } from 'react-credit-cards';
import './PaymentForm.css';
import 'react-credit-cards/es/styles-compiled.css';
import { usStates } from '../utils/us-states';
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';

interface CardInfo {
  cardNumber: string;
  cardHolder: string;
  expiry: string;
  cvc: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface PaymentFormProps {
  onCardPaymentSubmit: (data: any) => void;
  onPaypalPaymentSubmit: (data?: any) => void;
  termsAndConditionsConfirmed: boolean;
  paymentAmount: number;
  submitEnabled: boolean;
  setSubmitEnabled: (data: boolean) => void;
  paymentType: 'CARD' | 'PAY_PAL';
  setPaymentType: (data: 'CARD' | 'PAY_PAL') => void;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
                                                          termsAndConditionsConfirmed,
                                                          onCardPaymentSubmit,
                                                          onPaypalPaymentSubmit,
                                                          submitEnabled,
                                                          paymentAmount,
                                                          setSubmitEnabled,
                                                          paymentType,
                                                          setPaymentType,
                                                        }) => {
  const [cardInfo, setCardInfo] = useState<CardInfo>({
    cardNumber: '',
    cardHolder: '',
    expiry: '',
    cvc: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  const [focused, setFocused] = useState<Focused>();
  const [hidePaypal, setHidePaypal] = useState<boolean>(false);
  const [showBillingAddress, setShowBillingAddress] = useState<boolean>(false);

  useEffect(() => {
    // reinitialize paypal
    setHidePaypal(true)
    setTimeout(() => {
      setHidePaypal(false)
    },50)
  }, [paymentAmount]);

  const handleCardInfoChange = (event: any) => {
    setSubmitEnabled(true);
    const target = event.target;
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value, cardInfo.cvc, cardInfo);
    }
    console.log({
      ...cardInfo,
      [event.target.name]: event.target.value,
    });
    setCardInfo({
      ...cardInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    setSubmitEnabled(false);
    event.preventDefault();
    onCardPaymentSubmit(cardInfo);
  };

  const handleFocusChange = (event: any) => {
    setFocused(event.target.name);
  };

  const createPayPalOrder = (data: CreateOrderData, actions: CreateOrderActions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: paymentAmount.toFixed(2),
          },
        },
      ],
    });
  };

  const approvePayPalOrder = async (data: OnApproveData, actions: OnApproveActions) => {
    const details = await actions.order?.get()
    onPaypalPaymentSubmit(details?.id)
  };

  return (
    <section className='payment-section'>
      <h1>Payment</h1>
      <div className='section-container'>
        {!termsAndConditionsConfirmed &&
          <div className='error-info'>
            <img src='images/error-info-icon.svg' />
            <div>You must review and agree to the Terms Agreement above before payment options are enabled.</div>
          </div>}
        {termsAndConditionsConfirmed && <>
          <div className='supported-cards'>
            <div className='checkbox-container'>
              <div className='round-cb'>
                <input
                  type='checkbox'
                  id='card-checkbox'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPaymentType(e.target.checked ? 'CARD' : 'PAY_PAL');
                  }}
                  checked={paymentType === 'CARD'}
                />
                <label htmlFor='card-checkbox'></label>
              </div>
            </div>
            <div>
              <img src='images/discover.svg' />
              <img src='images/visa.svg' />
              <img src='images/mastercard.svg' />
              <img src='images/american.svg' />
            </div>
          </div>
          <div className='supported-cards'>
            <div className='checkbox-container'>
              <div className='round-cb'>
                <input
                  type='checkbox'
                  id='paypal-checkbox'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPaymentType(e.target.checked ? 'PAY_PAL' : 'CARD');
                  }}
                  checked={paymentType === 'PAY_PAL'}
                />
                <label htmlFor='paypal-checkbox'></label>
              </div>
            </div>
            <div>
              <img src='images/PayPal.svg' />
            </div>
          </div>
          {paymentType === 'CARD' && <> <Cards
            number={cardInfo.cardNumber}
            name={cardInfo.cardHolder}
            expiry={cardInfo.expiry}
            cvc={cardInfo.cvc}
            focused={focused}
          />
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <small>Name on card:</small>

                <input
                  type='text'
                  name='cardHolder'
                  className='form-control'
                  placeholder='Name'
                  pattern='[a-z A-Z-]+'
                  required
                  onChange={handleCardInfoChange}
                  onFocus={handleFocusChange}
                />
              </div>
              <div className='form-group'>
                <small>Card Number:</small>

                <input
                  type='tel'
                  name='cardNumber'
                  className='form-control'
                  placeholder='Card Number'
                  pattern='[\d| ]{16,22}'
                  maxLength={19}
                  required
                  onChange={handleCardInfoChange}
                  onFocus={handleFocusChange}
                />
              </div>

              <div className='form-group'>
                <small>Expiration Date:</small>

                <input
                  type='tel'
                  name='expiry'
                  className='form-control'
                  placeholder='Valid Thru'
                  pattern='\d\d/\d\d'
                  required
                  onChange={handleCardInfoChange}
                  onFocus={handleFocusChange}
                />
              </div>
              <div className='form-group'>
                <small>CVC:</small>

                <input
                  type='tel'
                  name='cvc'
                  className='form-control'
                  placeholder='CVC'
                  pattern='\d{4}|\d{3}'
                  required
                  onChange={handleCardInfoChange}
                  onFocus={handleFocusChange}
                />
              </div>
              <div className='switch-wrapper'>
                <div>Change billing name & address</div>
                <label className='switch'>
                  <input type='checkbox' checked={showBillingAddress}
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           setShowBillingAddress(e.target.checked);
                         }} />
                  <span className='slider round'></span>
                </label>
              </div>
              {showBillingAddress && <>
                <div className='form-group'>
                  <small>First Name:</small>
                  <input
                    type='text'
                    name='firstName'
                    className='form-control'
                    placeholder='First name'
                    onChange={handleCardInfoChange}
                    onFocus={handleFocusChange}
                  />
                </div>
                <div className='form-group'>
                  <small>Last Name:</small>

                  <input
                    type='text'
                    name='lastName'
                    className='form-control'
                    placeholder='Last name'
                    onChange={handleCardInfoChange}
                    onFocus={handleFocusChange}
                  />
                </div>
                <div className='form-group'>
                  <small>Address:</small>
                  <input
                    type='text'
                    name='address'
                    className='form-control'
                    placeholder='Address'
                    onChange={handleCardInfoChange}
                    onFocus={handleFocusChange}
                  />
                </div>
                <div className='form-group'>
                  <small>City:</small>
                  <input
                    type='text'
                    name='city'
                    className='form-control'
                    placeholder='City'
                    onChange={handleCardInfoChange}
                    onFocus={handleFocusChange}
                  />
                </div>
                <div className='zip-state-row'>
                  <div className='form-group'>
                    <small>State:</small>
                    <select
                      name='state'
                      className='form-control'
                      placeholder='State'
                      onChange={handleCardInfoChange}
                      onFocus={handleFocusChange}
                    >
                      <option value=''>State</option>
                      {Object.keys(usStates).map(state =>
                        (<option key={state} value={state}>{state}</option>),
                      )}
                    </select>
                  </div>
                  <div className='form-group'>
                    <small>Zip:</small>
                    <input
                      type='text'
                      name='zip'
                      className='form-control'
                      placeholder='Zip'
                      onChange={handleCardInfoChange}
                      onFocus={handleFocusChange}
                    />
                  </div>
                </div>
              </>}
              <div className='form-actions'>
                <button className='button-primary' disabled={!submitEnabled}>Pay Now</button>
              </div>
            </form>
          </>}
          {paymentType === 'PAY_PAL' && !hidePaypal &&
            <PayPalScriptProvider options={{
              clientId: process.env.REACT_APP_PAY_PAL_CLIENT_ID || '', currency: 'USD',
              intent: 'capture',
            }}>
              <PayPalButtons
                style={{ layout: 'horizontal' }}
                createOrder={createPayPalOrder}
                onApprove={approvePayPalOrder} />
            </PayPalScriptProvider>}
        </>}
        <div className='secure-info'>
          <img src='images/secure.svg' />
          <div>Your personal & payment information is secure.</div>
        </div>
      </div>
    </section>

  );
};
