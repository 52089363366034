import React from 'react';
import './Header.css';


export const Header: React.FC = () => {

  return (
    <header>
      <img src='images/rgbrenner-logo.svg' />
      <div className="contact-section">
        <a href="tel:18882774515">
          (888) APRIL-15
        </a>
        <a href="https://rgbrenner.com/contact" className='button-secondary-small'>Contact us</a>
      </div>
    </header>
  );
};
