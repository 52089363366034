import React from 'react';
import './ErrorPage.css';

export const PaymentUnsuccessful: React.FC = () => {
  return (
    <div className='error-page'>
      <img src='/images/payment-unsuccessful.svg' />
      <h1>Payment Unsuccessful</h1>
      <div className='info-text'>Ensure that the payment informations you provided are accurate and up to date.</div>
      <button className="button-primary" onClick={() => window.location.reload()}>Return to Payment Form</button>
    </div>
  );
};
